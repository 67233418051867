import { Heading, Button } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

const Chat = () => {
  const { session, logout } = useAuth()

  return (
    <div>
      <center>
        <Heading size={3}>
          Welcome {session.userInfo.firstName} {session.userInfo.lastName}!
        </Heading>
        <br />
        <br />
        <br />
        <br />
        <Heading size={1}>Chat Placeholder</Heading>
        <br />
        <br />
        <br />
        <Button onClick={logout}>Log Out</Button>
      </center>
    </div>
  )
}
export default Chat
