import { Heading, Button } from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'

const Home = () => {
  return (
    <div>
      <center>
        <br />
        <br />
        <br />
        <br />
        <Heading size={1}>Home Screen</Heading>
        <Button
          as={NavLink}
          exact="true"
          to="/chat"
          activeclassname="isSelected"
        >
          Chat
        </Button>
      </center>
    </div>
  )
}
export default Home
